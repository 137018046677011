export interface VerificationStatus {
  status: string;
  errorCode?: number;
}

export interface DocumentTransaction {
  timestamp: string;
  scanReference: string;
}

export interface Document {
  filename: string;
  documentType: string;
}

export interface Address {
  line1?: string;
  line2?: string;
  city?: string;
  postalCode: string;
}

export interface DocVerificationResult {
  status?: string;
  country: string;
  extractedData?: {
    address?: Address;
    issueDate?: string;
    name?: string;
    expiryDate?: string;
  };
  type: string;
}

export interface IdVerificationResult {
  idFirstName?: string;
  idLastName?: string;
  verificationStatus?: any;
  idScanStatus?: any;
}

export interface DocTransaction {
  status?: string;
}

export interface UserDocument {
  authId: string;
  nickname: string;
  email: string;
  createdAt: string;
  dvDocument?: Document;
  docVerificationStatus?: VerificationStatus;
  docVerificationResult?: DocVerificationResult;
  docTransaction?: DocTransaction;
  dvDocumentTransaction?: DocumentTransaction;
  docManualVerificationStatus?: {
    status?: string;
    operatorEmail?: string;
    modifyReason?: string;
  };
  amlManualCheckResult?: { status?: string; reason?: string };
  idVerificationResult?: IdVerificationResult;
  nameMatch?: boolean;
  fullName?: string;
  extractedName?: string;
  amlCheckResult: any;
}

export interface UserDoc extends UserDocument {
  issueDateInvalid: boolean;
  expiryDateInvalid: boolean;
}

export enum VerificationCodeType {
  unbind_wallet = 'unbind_wallet',
  reset_mfa = 'reset_mfa',
}

export interface VerificationCode {
  code: string;
  expireAt: number;
  type: VerificationCodeType;
}

export interface UserInspection {
  _id: string;
  userId: string;
  userEmail: string;
  result?: UserInspectionResult;
}

export enum InspectionStatus {
  none,
  checked,
  approved,
  denied,
}

export enum SystematicStatus {
  none,
  processing,
  done,
}

export interface UserInspectionResult {
  status: InspectionStatus;
  isSystematicError: boolean;
  systematicStatus: SystematicStatus;
  comments: string;
  operatorId: string;
  operatorEmail: string;
  lastModifiedAt: string;
}

export interface AdminAuditing {
  _id: string;
  userId: string;
  userEmail: string;
  event: number;
  createdAt: string;
}

export enum AuditingEvent {
  login,
  logout,
}

export interface ReconciliationError {
  _id: string;
  blockHash: string;
  extrinsicIndex: string;
  createdAt: string;
  extrinsicHash: string;
  updatedAt: string;
  source: string;
  from: string;
  to: string;
  ottoAmount: string;
  assetId: string;
  manualCheckResult: {
    status: 'APPROVED' | 'DENIED';
    operatorId: string;
    lastModifiedAt: string;
    reason: string;
  };
}

export interface EthTransaction {
  id: string;
  amount: number;
  blockNumber: number;
  blockTimestamp: string;
  txHash: string;
  status: string;
  source: string;
  ethWalletAddress: string;
  createdAt: Date;
  updatedAt: Date;
  ottoAmount: number;
  blockHash: string;
  extrinsicHash: string;
  extrinsicIndex: number;
  ottoTxTimestamp: number;
}

export interface KycReward {
  email: string;
  ottoWalletAddress: string;
  kycReward: {
    status: string;
    referenceId: string;
    blockHash: string;
  };
}

export enum ProposalStatus {
  PENDING = 'pending',
  ADMIN_APPROVED = 'admin_approved', // admin approved after review
  ADMIN_DECLINED = 'admin_declined', // admin declined after review
  READY_FOR_PAY = 'ready_for_pay', // need extra fee
  READY_FOR_VOTE = 'ready_for_vote',
  READY_FOR_EXECUTION = 'ready_for_execution', // successful after voting
  ADMIN_EXECUTED = 'admin_executed',
  REJECTED = 'rejected', // failed after voting
}

export enum ProposalType {
  GENERAL = 'general',
  FINANCE = 'finance',
  TECH = 'tech',
}

interface OperationInfo {
  operatorEmail: string;
  lastModifiedAt: Date;
  reason?: string;
  executeProposalDoc?: string;
}

interface Payers {
  who: string;
  amount: string;
}

interface ExtraPayment {
  paymentAssetId: string;
  amount: string;
  payers: Payers;
}

export interface Proposal {
  _id: string;
  authId: string;
  walletAddress: string;
  type: ProposalType;
  status: ProposalStatus;
  title: string;
  description?: string;
  snapshotBlockNumber?: number;
  votingProposalOperation?: OperationInfo;
  passConditions?: number;
  endTime?: Date;
  extraPayment?: ExtraPayment;
  executingProposalOperation?: OperationInfo;
  proposalHash?: string;
  proposalIndex?: string;
  threshold?: number;
  blockNumber?: number;
  blockHash?: string;
  extrinsicHash?: string;
  extrinsicIndex?: string;
  txIndex?: string;
  blockTimestamp?: number;
  attachedDoc?: string;
  email: string;
  discord: string;
}
