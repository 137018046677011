/* eslint-disable react/prop-types */
import { Button, CircularProgress } from '@mui/material';
import { Suspense, lazy, useState } from 'react';

const ExecuteProposalModal = lazy(() => import('./ExecuteProposalModal'));

const ExecuteProposalButton = ({ proposal }) => {
  const [openModal, setOpenModal] = useState<boolean>();
  const onOpenModal = () => {
    setOpenModal(true);
  };

  const onCloseModal = () => {
    setOpenModal(false);
  };

  return (
    <>
      <Button onClick={() => onOpenModal()}>Execute</Button>
      <Suspense fallback={<CircularProgress />}>
        <ExecuteProposalModal proposal={proposal} open={openModal} onClose={onCloseModal} />
      </Suspense>
    </>
  );
};

export default ExecuteProposalButton;
