import { useAuth0 } from '@auth0/auth0-react';
import { Button, Card, CardActions, CardContent, CardMedia, Grid, Typography } from '@mui/material';

const Login = () => {
  const { loginWithRedirect } = useAuth0();
  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      style={{ minHeight: '100vh' }}
    >
      <Grid item xs={3}>
        <Card sx={{ maxWidth: 345 }}>
          <CardMedia component="img" height="150" image="/static/images/doc-review.jpeg" alt="" />
          <CardContent>
            <Typography gutterBottom variant="h5" component="div">
              Document Centre
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {`Administrators can review all users' documents`}
            </Typography>
          </CardContent>
          <CardActions>
            <Button onClick={() => loginWithRedirect()}>Log In</Button>
          </CardActions>
        </Card>
      </Grid>
    </Grid>
  );
};

export default Login;
