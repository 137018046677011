/* eslint-disable react/prop-types */
import { Button, CircularProgress } from '@mui/material';
import { Suspense, lazy, useState } from 'react';

const UpdateOperatedReasonModal = lazy(() => import('./UpdateOperatedReasonModal'));

const UpdateOperatedReasonButton = ({ proposal }) => {
  const [openModal, setOpenModal] = useState<boolean>();
  const onOpenModal = () => {
    setOpenModal(true);
  };

  const onCloseModal = () => {
    setOpenModal(false);
  };

  return (
    <>
      <Button onClick={() => onOpenModal()}>Update reason</Button>
      <Suspense fallback={<CircularProgress />}>
        {openModal && (
          <UpdateOperatedReasonModal proposal={proposal} open={openModal} onClose={onCloseModal} />
        )}
      </Suspense>
    </>
  );
};

export default UpdateOperatedReasonButton;
