/* eslint-disable react/prop-types */
import { TableRow, TableCell, CircularProgress } from '@mui/material';

const LoadingTableRow = ({ loading }) => {
  return (
    loading && (
      <TableRow>
        <TableCell colSpan={5}>
          <CircularProgress></CircularProgress>
        </TableCell>
      </TableRow>
    )
  );
};
export default LoadingTableRow;
