/* eslint-disable react/prop-types */
import { TableRow, TableCell, Typography } from '@mui/material';

const ErrorTableRow = ({ error }) => {
  return error ? (
    <TableRow>
      <TableCell colSpan={8} align="center">
        <Typography variant="subtitle1" color="orange">
          {error?.message}
        </Typography>
      </TableCell>
    </TableRow>
  ) : null;
};

export default ErrorTableRow;
