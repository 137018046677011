import axios from 'axios';
import config from '../config';
import {
  AdminAuditing,
  EthTransaction,
  KycReward,
  Proposal,
  ProposalStatus,
  ReconciliationError,
  UserDocument,
  UserInspection,
  VerificationCodeType,
} from './types';

const composeConfig = (token: string) => {
  return {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };
};

export const getUserAmlCheckResults = async (
  token: string,
  skip: number,
  limit: number,
  search = ''
) => {
  try {
    const response = await axios.get<{
      data: UserDocument[];
      total: number;
    }>(`${config.serverUrl}/v1/verification/aml/review`, {
      ...composeConfig(token),
      params: {
        skip,
        limit,
        search,
      },
    });

    const { data, total } = response.data;

    return { data, total };
  } catch {
    const error = new Error(ERROR_MSG);

    return { error };
  }
};

export const getUserList = async (token: string, skip: number, limit: number, search = '') => {
  const url = `${config.serverUrl}/v1/verification/user/list`;
  try {
    const response = await axios.get<{
      data: UserDocument[];
      total: number;
    }>(url, {
      ...composeConfig(token),
      params: {
        skip,
        limit,
        search,
      },
    });

    const { data, total } = response.data;

    return { data, total };
  } catch {
    const error = new Error(ERROR_MSG);

    return { error };
  }
};

export const getUserDocDocuments = async (
  token: string,
  skip: number,
  limit: number,
  isArchived: boolean,
  isJumio: boolean,
  search = ''
) => {
  const url = `${config.serverUrl}/v1/verification/doc/review`;
  try {
    const response = await axios.get<{
      data: UserDocument[];
      total: number;
    }>(url, {
      ...composeConfig(token),
      params: {
        skip,
        limit,
        search,
        isArchived,
        isJumio,
      },
    });

    const { data, total } = response.data;

    return { data, total };
  } catch {
    const error = new Error(ERROR_MSG);

    return { error };
  }
};

export const getIdFailedUserDocuments = async (
  token: string,
  skip: number,
  limit: number,
  search = ''
) => {
  const url = `${config.serverUrl}/v1/verification/users/id-failed`;
  try {
    const response = await axios.get<{
      data: UserDocument[];
      total: number;
    }>(url, {
      ...composeConfig(token),
      params: {
        skip,
        limit,
        search,
      },
    });

    const { data, total } = response.data;

    return { data, total };
  } catch {
    const error = new Error(ERROR_MSG);

    return { error };
  }
};

const ERROR_MSG = "You don't have permission to access this site, please contact administrators.";

export const getUserDocument = async (token: string, scanReference: string) => {
  try {
    const response = await axios.get(
      `${config.serverUrl}/v1/verification/doc/review/${scanReference}`,
      {
        ...composeConfig(token),
        responseType: 'blob',
      }
    );

    const blob = new Blob([response.data], { type: 'application/image' });
    const url = URL.createObjectURL(blob);

    return { url };
  } catch {
    const error = new Error(ERROR_MSG);

    return { error };
  }
};

const updateDocumentStatus = async (
  token: string,
  userAuthId: string,
  scanReference: string,
  status: string,
  operatorEmail: string,
  modifyReason: string
) => {
  try {
    await axios.put(
      `${config.serverUrl}/v1/verification/doc/review/`,
      {
        userAuthId,
        scanReference,
        status,
        operatorEmail,
        modifyReason,
      },
      {
        ...composeConfig(token),
      }
    );
  } catch {
    const error = new Error(ERROR_MSG);

    return { error };
  }
};

export enum DocVerificationStatus {
  DONE = 'DONE',
  ERROR = 'ERROR',
  PENDING = 'PENDING',
}

export enum ManualCheckStatus {
  APPROVED = 'APPROVED',
  DENIED = 'DENIED',
}

export const acceptDocument = async (
  token: string,
  userAuthId: string,
  scanReference: string,
  operatorEmail: string,
  modifyReason = ''
) =>
  updateDocumentStatus(
    token,
    userAuthId,
    scanReference,
    ManualCheckStatus.APPROVED,
    operatorEmail,
    modifyReason
  );

export const declineDocument = async (
  token: string,
  userAuthId: string,
  scanReference: string,
  operatorEmail: string,
  modifyReason = ''
) =>
  updateDocumentStatus(
    token,
    userAuthId,
    scanReference,
    ManualCheckStatus.DENIED,
    operatorEmail,
    modifyReason
  );

const updateAmlManualCheckResult = async (
  token: string,
  userId: string,
  operatorEmail: string,
  reason: string,
  status: string
) => {
  try {
    await axios.put(
      `${config.serverUrl}/v1/verification/aml/review/${userId}`,
      {
        operatorEmail,
        status,
        reason,
      },
      {
        ...composeConfig(token),
      }
    );
  } catch {
    const error = new Error(ERROR_MSG);

    return { error };
  }
};

export const approveAmlManulCheck = async (
  token: string,
  userId: string,
  operatorEmail: string,
  reason: string
) => {
  return updateAmlManualCheckResult(
    token,
    userId,
    operatorEmail,
    reason,
    ManualCheckStatus.APPROVED
  );
};

export const denyAmlManulCheck = async (
  token: string,
  userId: string,
  operatorEmail: string,
  reason: string
) => {
  return updateAmlManualCheckResult(token, userId, operatorEmail, reason, ManualCheckStatus.DENIED);
};

export const changePassword = async (email: string | undefined) => {
  const url = `https://${config.auth0.domain}/dbconnections/change_password`;
  const res = await axios.post(
    url,
    {
      client_id: config.auth0.clientId,
      email,
      connection: 'Username-Password-Authentication',
    },
    {
      headers: { 'content-type': 'application/json' },
    }
  );

  return res.status === 201 ? { response: res.data } : { error: 'Change password failed' };
};

export const retreiveIdImage = async (email: string, token: string) => {
  try {
    const response = await axios.get(`${config.serverUrl}/v1/users/id_image/${email}`, {
      ...composeConfig(token),
      responseType: 'blob',
    });

    const blob = new Blob([response.data], { type: 'application/image' });
    const url = URL.createObjectURL(blob);

    return { url };
  } catch {
    const error = new Error('User ID image not found');
    return { error };
  }
};

export const retreiveEkycIdImage = async (email: string, token: string) => {
  try {
    const response = await axios.get(`${config.serverUrl}/v1/users/ekyc/id_image/${email}`, {
      ...composeConfig(token),
      responseType: 'blob',
    });

    const blob = new Blob([response.data], { type: 'application/image' });
    const url = URL.createObjectURL(blob);

    return { url };
  } catch {
    const error = new Error('User ID image not found');
    return { error };
  }
};

export const retreiveDocImage = async (reference: string, token: string) => {
  try {
    const response = await axios.get(`${config.serverUrl}/v1/users/doc_image/${reference}`, {
      ...composeConfig(token),
      responseType: 'blob',
    });

    const blob = new Blob([response.data], { type: 'application/image' });
    const url = URL.createObjectURL(blob);

    return { url };
  } catch {
    const error = new Error('User ID image not found');
    return { error };
  }
};

export const sendVerificationCode = async (
  email: string,
  type: VerificationCodeType,
  token: string
) => {
  try {
    const response = await axios.post(
      `${config.serverUrl}/v1/users/verification_code`,
      {
        email,
        type,
      },
      {
        ...composeConfig(token),
      }
    );

    return response.data;
  } catch {
    const error = new Error('cant get verificatioin code');
    return { error };
  }
};

export const adminUnbindOttoWallet = async (token: string, email: string) => {
  try {
    const response = await axios.put(
      `${config.serverUrl}/v1/users/admin_unbind_otto`,
      {
        email,
      },
      {
        ...composeConfig(token),
      }
    );

    return response;
  } catch {
    const error = new Error(ERROR_MSG);

    return { error };
  }
};

export const adminResetUserMfa = async (token: string, email: string) => {
  try {
    const response = await axios.post(
      `${config.serverUrl}/v1/users/admin_reset_mfa`,
      {
        email,
      },
      {
        ...composeConfig(token),
      }
    );

    return response;
  } catch {
    const error = new Error(ERROR_MSG);

    return { error };
  }
};

export const adminGetUserStats = async (token: string) => {
  try {
    const response = await axios.get<{
      kycFinished: number;
      idFinished: number;
      docFinished: number;
    }>(`${config.serverUrl}/v1/users/user/stats`, {
      ...composeConfig(token),
    });
    const { kycFinished, idFinished, docFinished } = response.data;
    return { kycFinished, idFinished, docFinished };
  } catch {
    const error = new Error(ERROR_MSG);

    return { error };
  }
};

export const getInspectionList = async (
  token: string,
  skip: number,
  limit: number,
  search = '',
  systematicErrorOnly = false
) => {
  const url = `${config.serverUrl}/v1/admin/inspection/list`;
  try {
    const response = await axios.get<{
      data: UserInspection[];
      total: number;
    }>(url, {
      ...composeConfig(token),
      params: {
        skip,
        limit,
        search,
        systematicErrorOnly,
      },
    });
    const { data, total } = response.data;
    return { data, total };
  } catch {
    return { error: new Error(ERROR_MSG) };
  }
};

export const getUserProfile = async (token: string, userId: string) => {
  const url = `${config.serverUrl}/v1/users/profile/${userId}`;
  try {
    const response = await axios.get<UserDocument>(url, {
      ...composeConfig(token),
    });
    return response.data;
  } catch {
    return { error: new Error(ERROR_MSG) };
  }
};

export const updateUserInspection = async (token: string, id: string, data: any) => {
  try {
    const response = await axios.put(`${config.serverUrl}/v1/admin/inspection/${id}`, data, {
      ...composeConfig(token),
    });

    return response;
  } catch {
    return { error: new Error('Can not update inspection') };
  }
};

export const updateSystematicStatus = async (token: string, id: string, data: any) => {
  try {
    const response = await axios.patch(
      `${config.serverUrl}/v1/admin/inspection/${id}/systematic-error-status`,
      data,
      {
        ...composeConfig(token),
      }
    );

    return response;
  } catch {
    return { error: new Error('Can not update inspection') };
  }
};

export const getAuditingList = async (token: string, skip: number, limit: number, search = '') => {
  const url = `${config.serverUrl}/v1/admin/auditing/list`;
  try {
    const response = await axios.get<{
      data: AdminAuditing[];
      total: number;
    }>(url, {
      ...composeConfig(token),
      params: {
        skip,
        limit,
        search,
      },
    });
    const { data, total } = response.data;
    return { data, total };
  } catch {
    return { error: new Error(ERROR_MSG) };
  }
};

export const createAuditingEvent = async (token: string, event: 'login' | 'logout') => {
  try {
    const response = await axios.post(
      `${config.serverUrl}/v1/admin/auditing`,
      { event },
      {
        ...composeConfig(token),
      }
    );

    return response;
  } catch {
    return { error: new Error('Can not create auditing event') };
  }
};

export const getReconciliationErrorList = async (
  token: string,
  skip: number,
  limit: number,
  search?: string
) => {
  const url = `${config.serverUrl}/v1/reconciliation/errors`;
  try {
    const response = await axios.get<{
      data: ReconciliationError[];
      total: number;
    }>(url, {
      ...composeConfig(token),
      params: {
        skip,
        limit,
        search,
      },
    });

    const { data, total } = response.data;

    return { data, total };
  } catch {
    const error = new Error(ERROR_MSG);

    return { error };
  }
};

export const updateReconErrorStatus = async (
  token: string,
  id: string,
  status: string,
  reason?: string
) => {
  const url = `${config.serverUrl}/v1/reconciliation/errors/${id}`;
  try {
    const response = await axios.put(
      url,
      {
        status,
        reason,
      },
      {
        ...composeConfig(token),
      }
    );

    return response.status === 204;
  } catch {
    const error = new Error(ERROR_MSG);

    return { error };
  }
};

export const getEthTransactions = async (
  token: string,
  source: 'pre-mining' | 'plug-deposit',
  skip: number,
  limit: number,
  search
) => {
  const url = `${config.serverUrl}/v1/transactions/eth-transactions/${source}`;
  try {
    const response = await axios.get<{
      data: EthTransaction[];
      count: number;
    }>(url, {
      ...composeConfig(token),
      params: {
        skip,
        limit,
        search,
      },
    });

    const { data, count } = response.data;

    return { data, count };
  } catch {
    const error = new Error(ERROR_MSG);

    return { error };
  }
};

export const getKycRewards = async (token: string, skip: number, limit: number, search) => {
  const url = `${config.serverUrl}/v1/transactions/kyc-rewards`;
  try {
    const response = await axios.get<{
      data: KycReward[];
      count: number;
    }>(url, {
      ...composeConfig(token),
      params: {
        skip,
        limit,
        search,
      },
    });

    const { data, count } = response.data;

    return { data, count };
  } catch {
    const error = new Error(ERROR_MSG);

    return { error };
  }
};

export const getProposals = async (
  token: string,
  skip: number,
  limit: number,
  status: string,
  search = ''
) => {
  const url = `${config.serverUrl}/v1/governance/admin/proposals?status=${status}`;
  try {
    const response = await axios.get<{
      data: Proposal[];
      total: number;
    }>(url, {
      ...composeConfig(token),
      params: {
        skip,
        limit,
        search,
      },
    });

    const { data, total } = response.data;
    return { data, total };
  } catch {
    const error = new Error(ERROR_MSG);

    return { error };
  }
};

export const reviewProposal = async (
  token: string,
  status: ProposalStatus,
  operatorEmail: string,
  reason: string,
  proposalId: string,
  startBlock?: number,
  endBlock?: number,
  snapshotBlockNumber?: number,
  passConditions?: number,
  extraPaymentAssetId?: string,
  extraPaymentAmount?: string
) => {
  try {
    await axios.put(
      `${config.serverUrl}/v1/governance/admin/proposal/review/`,
      {
        proposalId,
        snapshotBlockNumber,
        passConditions,
        startBlock,
        endBlock,
        status,
        operatorEmail,
        reason,
        extraPaymentAssetId,
        extraPaymentAmount,
      },
      {
        ...composeConfig(token),
      }
    );
  } catch {
    const error = new Error(ERROR_MSG);

    return { error };
  }
};

export const executeProposal = async (
  token: string,
  status: ProposalStatus,
  operatorEmail: string,
  reason: string,
  proposalId?: string,
  proposalHash?: string,
  proposalIndex?: string,
  threshold?: number,
  blockNumber?: number,
  blockTimestamp?: number,
  blockHash?: string,
  executeProposalDoc?: string
) => {
  try {
    await axios.put(
      `${config.serverUrl}/v1/governance/admin/proposal/execute/`,
      {
        proposalId,
        proposalHash,
        proposalIndex,
        threshold,
        blockNumber,
        blockTimestamp,
        blockHash,
        status,
        operatorEmail,
        reason,
        executeProposalDoc,
      },
      {
        ...composeConfig(token),
      }
    );
  } catch {
    const error = new Error(ERROR_MSG);

    return { error };
  }
};

export const updateProposalReviewReason = async (
  token: string,
  reason: string,
  proposalId: string
) => {
  try {
    await axios.put(
      `${config.serverUrl}/v1/governance/admin/proposals/${proposalId}/review/reason`,
      { reason },
      { ...composeConfig(token) }
    );
  } catch {
    const error = new Error(ERROR_MSG);
    return { error };
  }
};

export const getFeatureFlags = async () => {
  const response = await axios.get(`${config.serverUrl}/v1/feature-flags`);
  return response.data;
};

export const getPendingIDVerifications = async (
  token: string,
  skip: number,
  limit: number,
  search = ''
) => {
  try {
    const response = await axios.get<{
      data: UserDocument[];
      total: number;
    }>(`${config.serverUrl}/v1/verification/doc/pending`, {
      ...composeConfig(token),
      params: {
        skip,
        limit,
        search,
      },
    });

    const { data, total } = response.data;

    return { data, total };
  } catch {
    const error = new Error(ERROR_MSG);

    return { error };
  }
};

export const resetIDVerification = async (
  token: string,
  userId: string,
  operatorEmail: string,
  reason: string
) => {
  try {
    await axios.put(
      `${config.serverUrl}/v1/verification/doc/reset/${userId}`,
      {
        operatorEmail,
        modifyReason: reason,
      },
      {
        ...composeConfig(token),
      }
    );
  } catch {
    const error = new Error(ERROR_MSG);

    return { error };
  }
};
