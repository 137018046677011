/* eslint-disable react/display-name */
import { Suspense, lazy, useMemo } from 'react';
import { Navigate, RouteObject } from 'react-router-dom';

import SidebarLayout from 'src/layouts/SidebarLayout';

import SuspenseLoader from 'src/components/SuspenseLoader';
// import ProtectedRoute from './auth/protected-route';
import Login from './auth/login';
import GovernanceCentre from './content/applications/GovernanceCentre';
import useUserPermissions from './hooks/useUserPermissions';

const Loader = (Component) => (props) => (
  <Suspense fallback={<SuspenseLoader />}>
    <Component {...props} />
  </Suspense>
);

// Applications
const DocumentsCentre = Loader(lazy(() => import('src/content/applications/DocumentsCentre')));

const AddressVerificationCentre = Loader(
  lazy(() => import('src/content/applications/AddressVerificationCentre'))
);

const UsersCentre = Loader(lazy(() => import('src/content/applications/UsersCentre')));

const AdminCentre = Loader(lazy(() => import('src/content/applications/AdminCentre')));

const ReconciliationCentre = Loader(
  lazy(() => import('src/content/applications/ReconciliationCentre'))
);

const EthTransactionCentre = Loader(
  lazy(() => import('src/content/applications/EthTransactionCentre'))
);

const KycRewardCentre = Loader(lazy(() => import('src/content/applications/KycRewardCentre')));

const SignInNavigate = () => {
  const permissions = useUserPermissions();

  const isAdmin = useMemo(() => permissions.includes('view:documents'), [permissions]);

  return isAdmin ? <Navigate to="/documents" /> : <Navigate to="/governance" />;
};

export const getRoutes = (isAuthenticated: boolean) => {
  const routes: RouteObject[] = [
    {
      path: '/',
      element: !isAuthenticated ? <Login /> : <SignInNavigate />,
    },
    {
      path: '/id-verification',
      element: <SidebarLayout />,
      children: [
        {
          path: '/id-verification',
          element: isAuthenticated ? <DocumentsCentre /> : <Navigate to="/" />,
        },
      ],
    },
    {
      path: '/address-verification',
      element: <SidebarLayout />,
      children: [
        {
          path: '/address-verification',
          element: isAuthenticated ? <AddressVerificationCentre /> : <Navigate to="/" />,
        },
      ],
    },
    {
      path: '/users',
      element: <SidebarLayout />,
      children: [
        {
          path: '/users',
          element: isAuthenticated ? <UsersCentre /> : <Navigate to="/" />,
        },
      ],
    },
    {
      path: '/admin',
      element: <SidebarLayout />,
      children: [
        {
          path: '/admin',
          element: isAuthenticated ? <AdminCentre /> : <Navigate to="/" />,
        },
      ],
    },
    {
      path: '/reconciliation',
      element: <SidebarLayout />,
      children: [
        {
          path: '/reconciliation',
          element: isAuthenticated ? <ReconciliationCentre /> : <Navigate to="/" />,
        },
      ],
    },
    {
      path: '/pre-mining',
      element: <SidebarLayout />,
      children: [
        {
          path: '/pre-mining',
          element: isAuthenticated ? (
            <EthTransactionCentre title="Pre-mining Rewards" source="pre-mining" />
          ) : (
            <Navigate to="/" />
          ),
        },
      ],
    },
    {
      path: '/plug-deposit',
      element: <SidebarLayout />,
      children: [
        {
          path: '/plug-deposit',
          element: isAuthenticated ? (
            <EthTransactionCentre title="Plug-deposit Rewards" source="plug-deposit" />
          ) : (
            <Navigate to="/" />
          ),
        },
      ],
    },
    {
      path: '/kyc-reward',
      element: <SidebarLayout />,
      children: [
        {
          path: '/kyc-reward',
          element: isAuthenticated ? <KycRewardCentre /> : <Navigate to="/" />,
        },
      ],
    },
    {
      path: '/governance',
      element: <SidebarLayout />,
      children: [
        {
          path: '/governance',
          element: isAuthenticated ? <GovernanceCentre /> : <Navigate to="/" />,
        },
      ],
    },
  ];
  return routes;
};
