import App from './App';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import { createRoot } from 'react-dom/client';

import 'nprogress/nprogress.css';
import { SidebarProvider } from './contexts/SidebarContext';
import Auth0ProviderWithHistory from './auth/auth0-provider-with-history';
const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
 <HelmetProvider>
    <SidebarProvider>
      <BrowserRouter>
        <Auth0ProviderWithHistory>
          <App />
        </Auth0ProviderWithHistory>
      </BrowserRouter>
    </SidebarProvider>
  </HelmetProvider>
);


serviceWorker.unregister();
