import { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Grid, Card, Typography, TextField, Box } from '@mui/material';
import Footer from 'src/components/Footer';
import PageTitleWrapper from 'src/components/PageTitleWrapper';
import { useDebounce } from '../../../hooks/useDebounce';
import GovernanceTabs from './components/GovernanceTabs';
import ApiProvider from 'src/chain-api/api-provider';

const GovernanceCentre = () => {
  const [search, setSearch] = useState('');
  const debouncedSearchTerm = useDebounce(search, 500);

  return (
    <ApiProvider>
      <Helmet>
        <title>Governance Centre</title>
      </Helmet>
      <PageTitleWrapper>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Typography variant="h3" component="h3" gutterBottom>
              Governance Centre
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Search by title"
              value={search}
              placeholder="Search by title"
              onChange={(e) => setSearch(e.target.value)}
              fullWidth
            />
          </Grid>
        </Grid>
      </PageTitleWrapper>
      <Box sx={{ px: 4 }}>
        <Grid container direction="row" justifyContent="center" alignItems="stretch" spacing={3}>
          <Grid item xs={12}>
            <Card>
              <Card>
                <GovernanceTabs search={debouncedSearchTerm} />
              </Card>
            </Card>
          </Grid>
        </Grid>
      </Box>
      <Footer />
    </ApiProvider>
  );
};

export default GovernanceCentre;
