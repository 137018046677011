/* eslint-disable react/prop-types */
import { TableRow, TableCell, Typography, TableHead, Button } from '@mui/material';
import { ProposalStatus } from 'src/api/types';
import { shortenAddr } from 'src/utils';

import { truncateString } from 'src/utils/stringUtils';
import ViewProposalButton from './ViewProposalButton';
import ReviewProposalButton from './ReviewProposalButton';
import ExecuteProposalButton from './ExecuteProposalButton';
import UpdateOperatedReasonButton from './UpdateOperatedReasonButton';

export const ProposalTableHead = () => (
  <TableHead>
    <TableRow>
      <TableCell>Title</TableCell>
      <TableCell>Type</TableCell>
      <TableCell>Description</TableCell>
      <TableCell>Status</TableCell>
      <TableCell>Submitter</TableCell>
      <TableCell>Snapshot Block Number ?</TableCell>
      <TableCell>End date ?</TableCell>
      <TableCell>Pass condition ?</TableCell>
      <TableCell>Mimimum voting users ?</TableCell>
      <TableCell>Who reviewed ?</TableCell>
      <TableCell>Extra payment asset Id ?</TableCell>
      <TableCell>Extra payment amount ?</TableCell>
      <TableCell>Who executed ?</TableCell>
      <TableCell>Executed transaction ?</TableCell>
      <TableCell>Actions</TableCell>
    </TableRow>
  </TableHead>
);

const ProposalTableRow = ({ row }) => {
  return (
    <>
      <TableRow
        key={row.id}
        sx={{
          '&:last-child td, &:last-child th': { border: 0 },
        }}
      >
        <TableCell align="center">
          <Typography display="block">{row.title}</Typography>
        </TableCell>
        <TableCell align="center">
          <Typography variant="body1" display="block">
            {row.type}
          </Typography>
        </TableCell>
        <TableCell align="center">
          <Typography variant="body1" display="block">
            {truncateString(row.description.replace(/<[^>]*>/g, ''), 20)}
          </Typography>
        </TableCell>
        <TableCell align="center">
          <Typography variant="body1" display="block">
            {row.status}
          </Typography>
        </TableCell>
        <TableCell align="center">
          <Typography variant="body1" display="block">
            {shortenAddr(row.walletAddress)}
          </Typography>
        </TableCell>
        <TableCell align="center">
          <Typography variant="button" display="block">
            {row.snapshotBlockNumber}
          </Typography>
        </TableCell>
        <TableCell align="center">
          <Typography variant="body1" display="block">
            {row.endDate}
          </Typography>
        </TableCell>
        <TableCell align="center">
          <Typography variant="body1" display="block">
            {row.passCondition}
          </Typography>
        </TableCell>
        <TableCell align="center">
          <Typography variant="body1" display="block">
            {row.minimumVotingUsers}
          </Typography>
        </TableCell>
        <TableCell align="center">
          <Typography variant="body1" display="block">
            {row.votingProposalOperation?.operatorEmail}
          </Typography>
        </TableCell>
        <TableCell align="center">
          <Typography variant="body1" display="block">
            {row.extraPayment?.paymentAssetId}
          </Typography>
        </TableCell>
        <TableCell align="center">
          <Typography variant="body1" display="block">
            {row.extraPayment?.amount}
          </Typography>
        </TableCell>
        <TableCell align="center">
          <Typography variant="body1" display="block">
            {row.executingProposalOperation?.operatorEmail}
          </Typography>
        </TableCell>
        <TableCell align="center">
          <Typography variant="body1" display="block">
            {row.proposalHash}
          </Typography>
        </TableCell>
        <TableCell align="center">
          <ViewProposalButton proposal={row} />
          {row.status === ProposalStatus.PENDING && <ReviewProposalButton proposal={row} />}
          {row.status === ProposalStatus.READY_FOR_EXECUTION && (
            <ExecuteProposalButton proposal={row} />
          )}
          {row.status === ProposalStatus.ADMIN_DECLINED && (
            <UpdateOperatedReasonButton proposal={row} />
          )}
        </TableCell>
      </TableRow>
    </>
  );
};

export default ProposalTableRow;
