import { Auth0Provider } from '@auth0/auth0-react';
import config from '../../config';
import { useNavigate } from 'react-router-dom';

// eslint-disable-next-line react/prop-types
const Auth0ProviderWithHistory = ({ children }) => {
  const navigate = useNavigate();
  const domain = config.auth0.domain;
  const clientId = config.auth0.clientId;
  const audience = config.auth0.audience;

  const onRedirectCallback = (appState) => {
    navigate(appState?.returnTo || window.location.pathname);
  };

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      authorizationParams={{redirect_uri: window.location.origin, audience: audience}}
      onRedirectCallback={onRedirectCallback}
    >
      {children}
    </Auth0Provider>
  );
};

export default Auth0ProviderWithHistory;
