import { de } from 'date-fns/locale';

const config = {
  env: String(process.env.REACT_APP_ENV),
  isDev: String(process.env.REACT_APP_ENV) === 'development',
  isProd: String(process.env.REACT_APP_ENV) === 'production',
  auth0: {
    domain: String(process.env.REACT_APP_AUTH0_DOMAIN),
    clientId: String(process.env.REACT_APP_AUTH0_CLIENT_ID),
    audience: String(process.env.REACT_APP_AUTH0_AUDIENCE),
  },
  serverUrl: String(process.env.REACT_APP_SERVER_URL),
  name: String(process.env.REACT_APP_NAME),
  otto: {
    endpoint: String(process.env.REACT_APP_ENDPOINT),
  },
};

export const CHAIN_BLOCK_TIME = 6;

export const PAYMENT_ASSETS = [
  {
    assetId: '1',
    symbol: 'OTTO',
    decimals: 12,
  },
  {
    assetId: '2',
    symbol: 'CATA',
    decimals: 0,
  },
];

export default config;
