import { useRef, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { createAuditingEvent } from 'src/api';

function usePrevious(value) {
  const ref = useRef();

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
}

export const useLoginAuditing = () => {
  const { user, getAccessTokenSilently } = useAuth0();
  const prevUser = usePrevious(user);

  useEffect(() => {
    const createLoginEvent = async () => {
      const token = await getAccessTokenSilently();
      await createAuditingEvent(token, 'login');
    };

    if (!prevUser && user) {
      createLoginEvent();
    }
  }, [user, prevUser]);
};

export default useLoginAuditing;
