import AccountCircleTwoToneIcon from '@mui/icons-material/AccountCircleTwoTone';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import HomeIcon from '@mui/icons-material/Home';
import BadgeIcon from '@mui/icons-material/Badge';
import { SvgIcon } from '@mui/material';

export interface MenuItem {
  link?: string;
  icon?: typeof SvgIcon;
  badge?: string;
  items?: MenuItem[];
  name: string;
  permission: string;
}

export interface MenuItems {
  items: MenuItem[];
  heading: string;
}

const menuItems: MenuItems[] = [
  {
    heading: 'Documents Centre',
    items: [
      {
        name: 'ID Verification Centre',
        link: '/id-verification',
        permission: 'view:documents',
        icon: BadgeIcon,
      },
      {
        name: 'Address Verification',
        link: '/address-verification',
        permission: 'view:documents',
        icon: HomeIcon,
      },
      {
        name: 'Users',
        link: '/users',
        permission: 'view:documents',
        icon: AccountCircleTwoToneIcon,
      },
      {
        name: 'Admin',
        link: '/admin',
        permission: 'view:documents',
        icon: AdminPanelSettingsIcon,
      },
      {
        name: 'Reconciliation',
        link: '/reconciliation',
        permission: 'view:documents',
        icon: MenuBookIcon,
      },
      {
        name: 'Pre-mining',
        link: '/pre-mining',
        permission: 'view:documents',
        icon: LocalAtmIcon,
      },
      {
        name: 'Plug-deposit',
        link: '/plug-deposit',
        permission: 'view:documents',
        icon: AccountBalanceWalletIcon,
      },
      {
        name: 'Kyc-rewards',
        link: '/kyc-reward',
        permission: 'view:documents',
        icon: EmojiEventsIcon,
      },
      {
        name: 'Governance',
        link: '/governance',
        permission: 'view:proposals',
        icon: AccountBalanceIcon,
      },
    ],
  },
];

export default menuItems;
