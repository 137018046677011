import { useAuth0 } from '@auth0/auth0-react';
import { jwtDecode } from 'jwt-decode';
import { useEffect, useState } from 'react';

const useUserPermissions = () => {
  const [permissions, setPermissions] = useState<string[]>([]);
  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    getAccessTokenSilently().then((token) => {
      const decoded = jwtDecode<{
        permissions: string[];
      }>(token);
      setPermissions(decoded.permissions);
    });
  }, [getAccessTokenSilently]);

  return permissions;
};

export default useUserPermissions;
