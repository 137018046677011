import ThemeProvider from './theme/ThemeProvider';
import { CssBaseline } from '@mui/material';
import { useRoutes } from 'react-router-dom';
import { getRoutes } from './router';
import { useAuth0 } from '@auth0/auth0-react';
import useLoginAuditing from './hooks/useLoginAuditing';
import { FeatureFlagProvider } from './contexts/FeatureFlagProvider';

const App = () => {
  const { isAuthenticated } = useAuth0();
  const content = useRoutes(getRoutes(isAuthenticated));

  useLoginAuditing();

  return (
    <ThemeProvider>
      <FeatureFlagProvider>
        <CssBaseline />
        {content}
      </FeatureFlagProvider>
    </ThemeProvider>
  );
};
export default App;
